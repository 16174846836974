import ko from 'date-fns/locale/ko';
import React, { useEffect, useRef, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
// registerLocale('ko', ko);
import Joi from 'joi-browser';
import { PostVolunteer } from '../../../api/user';
import DefaultPopup from '../../../components/Popup/components/DefaultPopup';
import {
	AddFile,
	Container,
	InputWrapper,
	LoadingContainer,
	SubmitButton,
	Title,
} from './styled';

const VolunteerPopup = ({ show, onClose, closeToday }) => {
	const [time, set_time] = useState('');
	// const [date, setDate] = useState(new Date());
	const [date, setDate] = useState(false);
	const [group_name, set_group_name] = useState('');
	const [department, set_department] = useState('');
	const [position, set_position] = useState('');
	const [pdf_file, set_pdf_file] = useState('');
	const [loading, setLoading] = useState(false);

	const InsertVolunteer = (e) => {
		setLoading(true);
		const schema = {
			time: Joi.string()
				.required()
				.error(() => ({ message: '시간을 입력해주세요.' })),
			group_name: Joi.string()
				.required()
				.error(() => ({ message: '봉사 단체명을 입력해주세요.' })),
			department: Joi.string()
				.required()
				.error(() => ({ message: '소속을 입력해주세요.' })),
			position: Joi.string()
				.required()
				.error(() => ({ message: '직급을 입력해주세요.' })),
			pdf_file: Joi.string()
				.required()
				.error(() => ({ message: '파일을 추가해주세요.' })),
		};
		const { error, value } = Joi.validate(
			{
				time: time,
				group_name: group_name,
				department: department,
				position: position,
				pdf_file: file_name,
			},
			schema
		);

		if (error) {
			alert(error.details[0].message);
			setLoading(false);
		} else {
			const fd = new FormData();

			Array.from(pdf_file).map((pdf_file, key) => {
				fd.append('pdf_file', pdf_file);
			});
			fd.append('date', date.format('yyyy-MM-dd'));
			fd.append('time', time);
			fd.append('group_name', group_name);
			fd.append('department', department);
			fd.append('position', position);

			PostVolunteer(fd)
				.then((response) => {
					if (response.data.code == '200') {
						alert('완료되었습니다.');
						window.location.reload();
					} else {
						alert('실패되었습니다.');
					}
				})
				.catch((err) => {
					alert('서버 요청 오류!');
					setLoading(false);
				})
				.finally(() => setLoading(false));
		}
	};

	const [file_name, set_file_name] = useState('');

	const UploadFile = (e) => {
		set_pdf_file(e.target.files);
		set_file_name(
			e.target.files && e.target.files[0] && e.target.files[0].name
				? e.target.files[0].name
				: ''
		);
	};

	useEffect(() => {
		if (!show) {
			set_pdf_file('');
			set_file_name('');
		}
	});

	const ResetFile = (e) => {
		set_time('');
		setDate('');
		set_group_name('');
		set_department('');
		set_position('');
		if (ref.current && ref.current.value) {
			ref.current.value = '';
			set_pdf_file('');
			set_file_name('');
		} else {
		}
		onClose(e);
	};

	const ref = useRef();
	const department_args = [
		'MSP사업부',
		'신기술사업부',
		// '플랫폼개발부',
		// '테크개발부',
		// '마케팅전략부',
		'혁신사업본부',
		'정보보호서비스팀',
		'인프라사업부',
		'은행SI사업부',
		'그룹사SI사업부',
		'Biz운영부',
		'테크운영부',
		'은행SO은행부',
		'글로벌운영부',
		'보험IT운영부',
		'카드IT운영부',
		'그룹사IT운영부',
		'경영기획부',
		'경영지원부',
		'직원만족부',
		// '디지털사업본부',
		// '마케팅전략부',
		// 'IT개발1부',
		// 'IT개발2부',
		// '은행SO사업부',
		// '테크플랫폼부',
		// 'Biz플랫폼부',
		// '그룹사IT운영부',
		// '보험IT운영부',
		// '카드IT운영부',
		// '경영기획부',
		// '경영지원부',
	];

	return (
		<>
			<DefaultPopup
				show={show}
				onClose={ResetFile}
				width="600px"
				height="500px"
			>
				<Container>
					{loading ? <LoadingScreen /> : null}
					<Title>봉사활동</Title>
					<InputWrapper>
						<input
							type="number"
							name="time"
							value={time ? time : ''}
							onChange={(e) => set_time(e.target.value)}
							placeholder="시간 (숫자만입력)"
						/>

						<DatePicker
							selected={date ? date : ''}
							locale={ko}
							dateFormat="yyyy-MM-dd"
							onChange={(date) => setDate(date)}
							placeholderText="봉사 날짜"
						/>

						<input
							type="text"
							name="group_name"
							value={group_name ? group_name : ''}
							onChange={(e) => set_group_name(e.target.value)}
							placeholder="봉사 단체명"
						/>
						<select onChange={(e) => set_department(e.target.value)}>
							<option selected hidden>
								구분자
							</option>
							<option value="">아동/청소년</option>
							<option value="">다문화/글로벌</option>
							<option value="">노인</option>
							<option value="">장애인</option>
							<option value="">환경</option>
							<option value="">기타</option>
						</select>
						<select onChange={(e) => set_department(e.target.value)}>
							<option selected hidden>
								소속
							</option>
							{department_args.map((item, key) => (
								<option value={item}>{item}</option>
							))}
						</select>
						<input
							type="text"
							name="position"
							value={position ? position : ''}
							onChange={(e) => set_position(e.target.value)}
							placeholder="직급"
						/>
						<AddFile for="add" className="files">
							파일첨부
						</AddFile>
						<div className="file-name">{file_name ? '· ' + file_name : ''}</div>
						<input
							ref={ref}
							className="file-input"
							id="add"
							type="file"
							name="upload[]"
							onChange={UploadFile}
						/>

						<SubmitButton href="javascript:;" onClick={InsertVolunteer}>
							추가하기
						</SubmitButton>
					</InputWrapper>
				</Container>
			</DefaultPopup>
		</>
	);
};

export default VolunteerPopup;

Date.prototype.format = function (f) {
	if (!this.valueOf()) return ' ';

	var weekKorName = [
		'일요일',
		'월요일',
		'화요일',
		'수요일',
		'목요일',
		'금요일',
		'토요일',
	];

	var weekKorShortName = ['일', '월', '화', '수', '목', '금', '토'];

	var weekEngName = [
		'Sunday',
		'Monday',
		'Tuesday',
		'Wednesday',
		'Thursday',
		'Friday',
		'Saturday',
	];

	var weekEngShortName = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

	var d = this;

	return f.replace(
		/(yyyy|yy|MM|dd|KS|KL|ES|EL|HH|hh|mm|ss|a\/p)/gi,
		function ($1) {
			switch ($1) {
				case 'yyyy':
					return d.getFullYear(); // 년 (4자리)

				case 'yy':
					return (d.getFullYear() % 1000).zf(2); // 년 (2자리)

				case 'MM':
					return (d.getMonth() + 1).zf(2); // 월 (2자리)

				case 'dd':
					return d.getDate().zf(2); // 일 (2자리)

				case 'KS':
					return weekKorShortName[d.getDay()]; // 요일 (짧은 한글)

				case 'KL':
					return weekKorName[d.getDay()]; // 요일 (긴 한글)

				case 'ES':
					return weekEngShortName[d.getDay()]; // 요일 (짧은 영어)

				case 'EL':
					return weekEngName[d.getDay()]; // 요일 (긴 영어)

				case 'HH':
					return d.getHours().zf(2); // 시간 (24시간 기준, 2자리)

				case 'mm':
					return d.getMinutes().zf(2); // 분 (2자리)

				case 'ss':
					return d.getSeconds().zf(2); // 초 (2자리)

				case 'a/p':
					return d.getHours() < 12 ? '오전' : '오후'; // 오전/오후 구분

				default:
					return $1;
			}
		}
	);
};

String.prototype.string = function (len) {
	var s = '',
		i = 0;
	while (i++ < len) {
		s += this;
	}
	return s;
};

String.prototype.zf = function (len) {
	return '0'.string(len - this.length) + this;
};

Number.prototype.zf = function (len) {
	return this.toString().zf(len);
};

function LoadingScreen() {
	return <LoadingContainer>추가 중입니다...</LoadingContainer>;
}
