import { useEffect, useRef, useState } from 'react';
import ModalClose from '../../../../../vector/ModalClose';
import ModalPrev from '../../../../../vector/ModalPrev';
import { Wrapper } from './styled';
import NoneIcon from '../../../../../vector/NoonIcon';
import { useSelector } from 'react-redux';
import { QRCodeCanvas } from 'qrcode.react';
import CryptoJS from 'crypto-js';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

export default function Card({ modalToggle, activity }) {
	const [isOpen, setIsOpen] = useState(false);
	const [isOpen2, setIsOpen2] = useState(false);
	const [isActive, setIsActive] = useState(false);

	const [encryptedText, setEncryptedText] = useState('');

	const encryptData = () => {
		if (user?.ID) {
			setTimeout(() => {
				const key = 'dnjdlek'; // Replace with your secret key
				const encrypted = CryptoJS.AES.encrypt(
					String(user?.ID),
					key
				).toString();
				setEncryptedText(encodeURIComponent(encrypted));
			}, 300);
		}
	};

	useEffect(() => {
		if (user?.ID) {
			encryptData();
		}
	}, [user?.ID]);

	// useEffect(() => {
	// 	if (localStorage.getItem('user_token')) {
	// 		// 수시로 토큰 체크
	// 		isLogined().then((response) => {
	// 			if (response.data.data.status == 200) {
	// 				alert(1);
	// 			}
	// 		});
	// 	}
	// }, []);

	useEffect(() => {
		document.body.classList.add('card');

		return function cleanup() {
			document.body.classList.remove('card');
		};
	}, []);

	const user = JSON.parse(localStorage.getItem('user_data'));

	const toggleDropdown = () => {
		setIsOpen(!isOpen);
		setIsActive(!isActive);
	};

	const qrToggle = () => {
		setIsOpen2(!isOpen2);
	};

	const [isOther, setOther] = useState(false);
	console.log(123, user);

	const copy_input = useRef('');

	const CopyLink = (e) => {
		e.preventDefault();
		copy_input.current.select();
		document.execCommand('copy');
		e.target.focus();
		alert('복사완료');
	};

	const [activeTab, setActiveTab] = useState('1');

	const TabClick = (tab) => {
		setActiveTab(tab);
	};

	const taglist = [
		{
			tag: 'IT품질관리자',
		},
		{
			tag: '신입사원',
		},
		{
			tag: '관리',
		},
		{
			tag: '관리',
		},
	];

	const settings = {
		arrows: false,
		dots: true,
		infinite: false,
		speed: 500,
		slidesToShow: 1,
	};

	return (
		<>
			<Wrapper>
				<section className={`modal-wrapper ${isOpen ? 'open' : ''}`}>
					<div className="overlay" onClick={modalToggle}></div>
					<div className={`modal-wrap ${isOpen ? 'open' : ''}`}>
						<div className="top">
							<div className="tit-box">
								<i
									onClickCapture={() => window.history.back()}
									className="back"
									onClick={modalToggle}
								>
									<img
										src={
											isOther
												? '/images/icon-closebtn02.svg'
												: '/icon-back03.svg'
										}
										alt=""
									/>
								</i>
								<h2>전자명함</h2>
							</div>
							<div onClick={CopyLink} className="share-btn">
								<img src="/images/icon-copy03.svg" alt="" />
							</div>
						</div>
						<div className="name-card-wrapper">
							<Slider {...settings}>
								<div className="user-box">
									<div className="logo">
										<img src="/images/kb-logo.png" alt="" />
									</div>
									<div className="name-box">
										<h1>{user?.user_name}</h1>
										<div className="position">
											<p className="department">{user?.user_department}</p>
											<p>{user?.position}</p>
										</div>
									</div>
									<div className="address-box">
										<p className="address">
											{user?.address}
											{/* 서울시 마포구 마포대로 78 경찰공제회 자람빌딩 14층 */}
										</p>
										<div className="flex">
											<h6>E</h6>
											<p>{user?.user_email}</p>
										</div>
										<div className="tell">
											<div className="flex">
												<h6>T</h6>
												<p>{user?.tel}</p>
											</div>
											<div className="flex">
												<h6>F</h6>
												<p>{user?.fax}</p>
											</div>
										</div>
										<div className="flex flex2">
											<h6>M</h6>
											<p>{user?.user_phone}</p>
										</div>
									</div>
								</div>
								<div className="career-box">
									<div className="top-menu">
										<div className="career">
											<img src="/images/icon-comment.svg" alt="" />
											<p>경력 사항</p>
										</div>
										<a
											href={`https://wp.daitso.kbds.co.kr/wp-admin/profile.php`}
											className="pen"
										>
											<img src="/images/icon-pen.svg" alt="" />
										</a>
									</div>
									<div className="user-info">
										<div className="txt">
											<h2>{user?.card_intro}</h2>
											<h3>
												{user?.user_name}
												{user?.position ? ` ${user?.position}` : null}입니다.
											</h3>
										</div>
										<div
											className="user"
											style={{
												backgroundImage: `url(${user?.user_avatar_url_original})`,
											}}
										></div>
									</div>
									<div className="detail">
										<h3>{user?.profile}</h3>

										<p>
											{user?.sub_career1}
											<br />
											{user?.sub_career2}
											<br />
											{user?.sub_career3}
										</p>
									</div>
									<div className="tag-list">
										{user?.user_tag?.length
											? user?.user_tag.map((item, key) => (
													<div className="tag" key={key}>
														# {item}
													</div>
											  ))
											: null}
									</div>
									{user?.post_link ? (
										<div className="share-box">
											<a href={user?.post_link} className="share">
												<img src="/images/icon-share02.svg" alt="" />
												<span>{user?.post_title}</span>
											</a>
										</div>
									) : null}
								</div>
							</Slider>
						</div>

						<div className="qr-wrap">
							<div className="qr-box">
								<div className="tit-box">
									<img src="/images/icon-qr02.svg" alt="" />
									<p className="tn">QR 코드</p>
								</div>
								<div className="qrcode-box">
									<QRCodeCanvas
										value={`https://blog.kbds.co.kr/card/${user?.namecard_hash}`}
									/>
									<div className="flex">
										<img src="/icon-photo.svg" alt="" />
										<p>찍어서 공유</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
				<input
					type="text"
					ref={copy_input}
					name="copy_account"
					value={
						user?.namecard_hash
							? `https://blog.kbds.co.kr/card/${user?.namecard_hash}`
							: ''
					}
				/>
			</Wrapper>
		</>
	);
}
